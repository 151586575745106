<template>
  <div>
    <div
      v-if="type === 'edit' && !editFormDataPopulated"
      class="row justify-center items-center"
    >
      <q-spinner color="primary" size="3em" />
    </div>
    <div v-else>
      <q-form @submit="$emit('submit', formData)">
        <FormCard
          title="Account Details"
          message="Some basic information about your account."
        >
          <template v-slot:inputs>
            <q-input
              filled
              v-model="formData.name"
              label="Account Name"
              lazy-rules
              :rules="[
                val =>
                  (val && val.length > 1) || 'Enter a name for your account.'
              ]"
              :disable="submitting"
              hint="Enter a name for your account."
            />

            <div>
              <q-select
                filled
                v-model="formData.countryCode"
                :options="countryCodeOptions"
                label="Country"
                emit-value
                map-options
                :rules="[
                  val =>
                    (val && val.length > 0) ||
                    'The country your audience is located in.'
                ]"
                :disable="submitting || type !== 'create'"
                hint="The country your audience is located in."
              />
              <!--<q-select
                v-if="type !== 'create'"
                filled
                v-model="formData.productTypeId"
                :options="productTypeOptions"
                label="Product Type"
                emit-value
                map-options
                disable
              />-->
              <q-tooltip v-if="type !== 'create'" anchor="top middle">
                Some account details cannot be changed. Please create a new
                account if you wish to use different values.
              </q-tooltip>
            </div>
          </template>
        </FormCard>

        <!--<FormCard
          v-if="type === 'create'"
          title="Choose Your Product Type"
          class="q-mt-md"
        >
          <template v-slot:message>
            Select the type of product you will be advertising.
            <a
              href="javascript:void(0)"
              @click="promptProductTypeLearnMorePanel"
              >Learn More</a
            >
          </template>
          <template v-slot:inputs>
            <SelectionTiles
              v-model="formData.productTypeId"
              :options="productTypeOptions"
            />
          </template>
        </FormCard>-->

        <template v-if="showFullForm">
          <FormCard title="Bid & Spend Limit" class="q-mt-md">
            <template v-slot:message>
              Use bid and spend limit to control how your {{ $store.state.productTypeConfigs.type }}s are served.
              <PageBanner
                title="Reminder"
                :message="'You have the ability to supply an individual bid value for each ' + $store.state.productTypeConfigs.type + ' in your inventory source(s). If a bid value is supplied for a ' + $store.state.productTypeConfigs.type + ', we will use that ' + $store.state.productTypeConfigs.type + '\'s bid when serving it.'"
                type="info"
                class="q-mt-md"
                flat
                bordered
              />
            </template>
            <template v-slot:inputs>
              <q-list class="q-mt-none q-mb-md">
                <q-item tag="label" class="q-pl-none">
                  <q-item-section avatar class="q-pr-sm">
                    <q-radio v-model="formData.bidMethod" val="automated" color="primary" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label :class="{ 'text-weight-bold': formData.bidMethod == 'automated' }">Automated Bidding (Recommended)</q-item-label>
                    <q-item-label class="text-font-secondary">We'll automate your bids, and focus on getting you the most clicks at the lowest possible cost-per-click.</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item tag="label" class="q-pl-none">
                  <q-item-section avatar class="q-pr-sm">
                    <q-radio v-model="formData.bidMethod" val="manual" color="primary" />
                  </q-item-section>
                  <q-item-section>
                    <q-item-label :class="{ 'text-weight-bold': formData.bidMethod == 'manual' }">Manual Bidding</q-item-label>
                    <q-item-label class="text-font-secondary">You will manually set your bid, and we will aim to keep your cost-per-click at, or below, that bid.</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>

              <q-input
                v-if="formData.bidMethod == 'manual'"
                filled
                v-model="formData.staticBidValue"
                label="Bid Value (per click)"
                mask="#.##"
                prefix="$"
                fill-mask="0"
                reverse-fill-mask
                lazy-rules
                :rules="[
                  val =>
                    (val && val > 0) ||
                    'Enter the default bid value for your account.'
                ]"
                :disable="submitting"
                :autofocus="this.$route.query.bid_strategy_initial == 1"
              />

              <q-input
                filled
                v-model="formData.dailyBudgetValue"
                label="Daily Spend Limit"
                prefix="$"
                inputmode="numeric"
                mask="#"
                reverse-fill-mask
                :disable="submitting"
                hint="Set a limit on how much your account will spend each day. If you don't want a limit, leave this blank."
                :autofocus="this.$route.query.daily_budget_initial == 1"
              />
            </template>
          </FormCard>

          <FormCard
            title="Tracking"
            message="To help you track where your visitors are from."
            class="q-mt-md"
          >
            <template v-slot:inputs>
              <q-input
                filled
                v-model="formData.trackingUrlParams"
                label="URL Parameters (Optional)"
                stack-label
                :placeholder="'&utm_source=' + $store.state.branding.name.toLowerCase() + '&utm_campaign=ACCOUNT_ID'"
                :disable="submitting"
                bottom-slots
              >
                <template v-slot:hint>
                  We'll automatically append these parameters to your product's
                  destination url. If you enter
                  <span class="text-weight-medium">ACCOUNT_ID</span> as a
                  parameter value, we'll auto-replace it with your account's id
                  after creation.
                </template>
              </q-input>
            </template>
          </FormCard>

          <FormActionsSection>
            <template v-slot:primary-actions>
              <q-btn
                :label="
                  customSubmitLabel.length > 0
                    ? customSubmitLabel
                    : type === 'create'
                    ? 'Create Account'
                    : 'Save Changes'
                "
                type="submit"
                color="primary"
                :disable="submitting"
                :loading="submitting"
              />
              <q-btn
                flat
                label="Cancel"
                :to="{ name: 'AdvertiserAccountsOverview' }"
                color="subtle"
                :disable="submitting"
              />
            </template>
          </FormActionsSection>
        </template>
      </q-form>
    </div>
  </div>
</template>

<script>
import FormActionsSection from "@/components/UI/FormActionsSection";
import FormCard from "@/components/UI/FormCard";
import PageBanner from "@/components/UI/PageBanner.vue";
import AccountsMixin from "@/mixins/Advertiser/AccountsMixin";

export default {
  name: "AdvertiserAccountForm",
  mixins: [AccountsMixin],
  components: { PageBanner, FormCard, FormActionsSection },
  props: {
    type: {
      type: String,
      required: true
    },
    advertiserAccountId: {
      type: String,
      required: false
    },
    submitting: {
      type: Boolean,
      required: false,
      default: false
    },
    customSubmitLabel: {
      type: String,
      default: ""
    },
    // TODO: Add this?
    prefillData: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      formData: {
        name: "",
        productTypeId: this.$store.state.productTypeConfigs.id,
        bidMethod: "automated",
        staticBidValue: "",
        dailyBudgetValue: "",
        countryCode: "US",
        trackingUrlParams: ""
      },
      // TODO: Convert below into endpoints, so data is dynamic as we add new product types and countries? Or should be manually added to front-end?
      productTypeOptions: [
        {
          icon: "sym_r_category",
          label: "Generic",
          value: "0",
          description:
            "If your product type does not apply to any other category, then choose Generic for your product type."
        },
        {
          icon: "sym_r_directions_car",
          label: "Vehicles",
          value: "1",
          description:
            "New, used or certified pre-owned (CPO) vehicles available for sale."
        },
        {
          icon: "sym_r_clinical_notes",
          label: "Jobs",
          value: "2",
          description: "Job postings for companies that are currently hiring."
        },
        {
          icon: "sym_r_home_work",
          label: "Real Estate",
          value: "3",
          description:
            "Apartments, condos and homes available for sale or rent.",
          disabled: true
        },
        {
          icon: "sym_r_airplane_ticket",
          label: "Travel",
          value: "4",
          description:
            "Flights, hotels, car rentals and destination packages available for purchase.",
          disabled: true
        }
      ],
      countryCodeOptions: [
        { label: "Australia", value: "AU" },
        { label: "Canada", value: "CA" },
        {
          label: "United Kingdom of Great Britain and Northern Ireland",
          value: "GB"
        },
        { label: "United States of America", value: "US" }
      ]
    };
  },
  computed: {
    editFormDataPopulated() {
      return this.formData.name !== "";
    },
    showFullForm() {
      return this.formData.productTypeId > 0;
    }
  },
  mounted() {
    if (this.type === "edit" && this.advertiserAccountId.length > 0) {
      this.getAdvertiserAccount(this.advertiserAccountId);
    }
  },
  methods: {
    getAdvertiserAccount(advertiserAccountId) {
      this.$store
        .dispatch("advertiserAccounts/GET_REQUEST", {
          params: {
            id: advertiserAccountId
          }
        })
        .then(data => {
          if (data.length > 0) {
            let advertiserAccount = data[0];
            this.formData = {
              name: advertiserAccount.name,
              productTypeId: advertiserAccount.product_type_id,
              staticBidValue: advertiserAccount.static_bid_value,
              dailyBudgetValue: advertiserAccount.daily_budget,
              countryCode: advertiserAccount.country_code,
              trackingUrlParams: "",
              bidMethod: advertiserAccount.bid_method
            };
          }
        });
    }
  }
};
</script>

<style scoped lang="scss"></style>
